<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                {{$t('message.permission')}}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'PermissionController@store'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>
              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>
              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>
            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>
              <th v-if="columns.name.show">
                <crm-input
                  placeholder="Имя разрешения"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.name"
                ></crm-input>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>
          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="(permission, index) in list"
              :key="'permissions-' + index"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ permission.id }}</td>
              <td v-if="columns.name.show">{{ permission.name }}</td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="permission"
                  :permissionShow="'PermissionController@update'"
                  :permissionDestroy="'PermissionController@destroy'"
                  :actions="actions"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :append-to-body="true"
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="80%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  mixins: [list],
  name: "PermissionController",
  components: { CrmUpdate },
  data() {
    return {
        test:''
    };
  },
  computed: {
    ...mapGetters({
      list: "permissions/list",
      columns: "permissions/columns",
      pagination: "permissions/pagination",
      statues: "permissions/statues",
      filter: "permissions/filter",
      sort: "permissions/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "permissions/index",
      setPagination: "permissions/setPagination",
      updateSort: "permissions/updateSort",
      updateFilter: "permissions/updateFilter",
      updateColumn: "permissions/updateColumn",
      updatePagination: "permissions/updatePagination",
      show: "permissions/show",
      empty: "permissions/empty",
      delete: "permissions/destroy",
      refreshData: "permissions/refreshData",
    }),
  },
};
</script>
